import { endOfWeek, startOfWeek, subMonths } from 'date-fns/esm';
import {
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from 'date-fns/esm/fp';
import { flow } from 'lodash-es';
export const CUSTOM_RANGE_TYPE_ID = 'time-range.preset.custom';
export interface TRange {
  name?: string;
  indexName?: string;
  rangeType: 'day' | 'week' | 'month' | 'quarter' | 'year';
  from: Date;
  to: Date;
  afterParse?(range: TRange): TRange;
  custom?: { add: (date: Date) => Date; sub: (date: Date) => Date };
}
export interface TRangePresets {
  [key: string]: TRange;
}
const TIME_RANGES = {
  year: <TRange>{
    indexName: '_0year',
    rangeType: 'year',
    name: 'time-range.preset.year',
    from: flow(startOfYear)(new Date()),
    to: flow(endOfYear)(new Date()),
    afterParse: (d: TRange) => {
      (d.from = flow(startOfYear)(d.from)), (d.to = flow(endOfYear)(d.to));
      return d;
    },
  },
  quarter: <TRange>{
    indexName: '_1quarter',
    rangeType: 'quarter',
    name: 'time-range.preset.quarter',
    from: flow(startOfQuarter)(new Date()),
    to: flow(endOfQuarter)(new Date()),
    afterParse: (d: TRange) => {
      (d.from = flow(startOfQuarter)(d.from)), (d.to = flow(endOfQuarter)(d.to));
      return d;
    },
  },
  // month: <TRange>{
  //   indexName: '_2l30d',
  //   rangeType: 'month',
  //   name: 'time-range.preset.last-30-days',
  //   from: flow(subDays(29), startOfDay)(new Date()),
  //   to: flow(endOfDay)(new Date()),
  //   custom: {
  //     add: (date) => flow(addDays(30))(date),
  //     sub: (date) => flow(subDays(30))(date),
  //   },
  // },
  tmonth: <TRange>{
    indexName: '_3tmonth',
    rangeType: 'month',
    name: 'time-range.preset.month',
    from: flow(startOfMonth)(new Date()),
    to: flow(endOfMonth)(new Date()),
    afterParse: (d: TRange) => {
      (d.from = flow(startOfMonth)(d.from)), (d.to = flow(endOfMonth)(d.to));
      return d;
    },
  },
  lmonth: <TRange>{
    indexName: '_4lmonth',
    rangeType: 'month',
    name: 'time-range.preset.last-month',
    from: flow((s) => subMonths(s, 1), startOfMonth)(new Date()),
    to: flow((s) => subMonths(s, 1), endOfMonth)(new Date()),
    afterParse: (d: TRange) => {
      d.from = flow(startOfMonth)(d.from);
      d.to = flow(endOfMonth)(d.to);
      return d;
    },
  },
  week: <TRange>{
    indexName: '_5week',
    rangeType: 'week',
    name: 'time-range.preset.week',
    from: flow(startOfWeek)(new Date(), { weekStartsOn: 1 }),
    to: flow(endOfWeek)(new Date(), { weekStartsOn: 1 }),
  },
  day: <TRange>{
    indexName: '_6day',
    rangeType: 'day',
    name: 'time-range.preset.day',
    from: flow(startOfDay)(new Date()),
    to: flow(endOfDay)(new Date()),
  },
};
export const TIME_RANGE_CUSTOM_NAME = 'time-range.preset.custom';
export const TIME_RANGE_DEFAULT_RANGES: TRangePresets & typeof TIME_RANGES = TIME_RANGES;
export function getTimeRangeKey(rangeName: string) {
  return Object.entries(TIME_RANGES).find(([key, value]) => value.name === rangeName)?.[0];
}
export function getTimeRangeByType(type: string) {
  return Object.entries(TIME_RANGES).find(([key, value]) => value.rangeType === type)?.[1];
}
export function getTimeRangeByKey(rangeName: string) {
  return Object.entries(TIME_RANGES).find(([key, value]) => value.name === rangeName)?.[1];
}
